import React from "react";
import { styled } from "styled-components";

import NoNftsImageDark from "../../../../../apps/web/src/views/Nft/market/components/Activity/NoNftImageDark";

import BackgroundImage from "./BackgroundImage";
import { BackgroundImageProps } from "./types";

const StyledProfileAvatar = styled(BackgroundImage)`
  border-radius: 50%;
`;

const StyledSquirrelPlaceholder = styled(NoNftsImageDark)`
  height: 33px;
  width: 33px;
`;
// theme.isDark ?

const ProfileAvatar: React.FC<React.PropsWithChildren<BackgroundImageProps>> = (props) => (
  <StyledProfileAvatar loadingPlaceholder={<StyledSquirrelPlaceholder />} {...props} />
);

export default ProfileAvatar;
