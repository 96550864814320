import { styled } from 'styled-components'

interface NoNftImageDarkProps {
  bordercolor?: string
  alt?: string
  width?: string
  height?: string
}

const NoNftImageDark = styled.div.attrs<NoNftImageDarkProps>(({ alt }) => ({
  alt,
}))<NoNftImageDarkProps>`
  background: url('/images/nfts/no-profile-md${({ theme }) => (theme.isDark ? '-dark' : '')}.png');
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  position: relative;
  width: ${({ width }) => width || '96px'};
  height: ${({ height }) => height || '96px'};
  background-color: ${({ theme }) => theme.colors.background};
  border: 1px ${({ bordercolor }) => bordercolor || '#f2ecf2'} dashed;

  & > img {
    border-radius: 50%;
  }
`

export default NoNftImageDark
