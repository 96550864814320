import { Box, Flex, Grid, Image, ImageProps, Text } from '@pancakeswap/uikit'
import { NextLinkFromReactRouter } from '@pancakeswap/widgets-internal'
import { styled } from 'styled-components'

export const TwoColumnsContainer = styled(Flex)`
  gap: 22px;
  align-items: flex-start;
  & > div:first-child {
    flex: 1;
    gap: 20px;
  }
  & > div:last-child {
    flex: 2;
  }
`

export const RoundedImage = styled(Image)`
  height: max-content;
  border-radius: ${({ theme }) => theme.radii.default};
  overflow: hidden;
  & > img {
    object-fit: contain;
  }
`
interface RoundedImageGraphProps extends ImageProps {
  zoom?: number // Add the zoom prop
}

export const RoundedImageGraph = styled(Image)<RoundedImageGraphProps>`
  height: max-content;
  border-radius: ${({ theme }) => theme.radii['8px']};
  overflow: hidden;
  & > img {
    object-fit: cover; // Change from contain to cover
    // transform: ${({ zoom }) => `scale(${zoom || 1})`}; // Dynamically apply zoom
    transform: ${({ zoom }) =>
      `scale(${zoom || 1}) translateY(${zoom && zoom > 1 ? '-4px' : '0'})`}; // Apply zoom and shift up
    transition: transform 0.3s ease; // Add smooth zoom effect
  }
`

export const RoundedVideoGraph = styled.video<{ zoom?: number }>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: ${({ zoom }) => `scale(${zoom || 1}) translateY(${zoom && zoom > 1 ? '-4px' : '0'})`};
  transition: transform 0.3s ease;
`

export const SmallRoundedImage = styled(Image)`
  & > img {
    border-radius: ${({ theme }) => theme.radii.default};
  }
`

export const Container = styled(Flex)`
  gap: 24px;
`

export const CollectionLink = styled(NextLinkFromReactRouter)`
  color: ${({ theme }) => theme.colors.primary};
  display: block;
  font-weight: 600;
  margin-top: 16px;

  ${({ theme }) => theme.mediaQueries.lg} {
    margin-top: 50px;
  }
`

export const FirstEditionContainer = styled('div')`
  color: ${({ theme }) => theme.colors.primary};
  display: block;
  //  font-weight: 600;
  margin-top: 16px;
`

export const CollectibleRowContainer = styled(Grid)`
  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
`

export const StyledSortButton = styled.button`
  border: none;
  cursor: pointer;
  background: none;
  color: ${({ theme }) => theme.colors.secondary};
  font-weight: bold;
`

export const ButtonContainer = styled(Box)`
  text-align: right;
  padding-right: 24px;
`

export const TableHeading = styled(Grid)`
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.cardBorder}`};
`

export const FlowContainerWrapper = styled(Text)`
  position: relative; // position relative is needed for the particles to work
  background: radial-gradient(ellipse at bottom, #1a2030 0%, #070a10 100%);

  #tsparticles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .react-flow {
    --bg-color: rgb(17, 17, 17, 0.8);
    --text-color: rgb(243, 244, 246);
    --node-box-shadow: 10px 0 15px rgba(247, 218, 182, 0.3), -10px 0 15px rgba(251, 238, 222, 0.3);
    // background-color: var(--bg-color);
    background-color: transparent;
    // background: radial-gradient(ellipse at bottom, #1a2030 0%, #070a10 100%);
    color: var(--text-color);
  }

  .react-flow__node-turbo.no-glow {
    --node-box-shadow: none;
    // filter: drop-shadow(0px 0px 12px #f7dab6);
    animation-name: svgglow;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    filter: drop-shadow(2px 2px 6px #f2c288) drop-shadow(2px -2px 6px #fbeede);
  }

  .react-flow__node-turbo.circle {
    --node-border-radius: 100%;
    border-radius: var(--node-border-radius);
  }

  @keyframes svgglow {
    0% {
      filter: drop-shadow(2px 2px 6px #f2c288) drop-shadow(2px -2px 6px #fbeede);
    }
    100% {
      filter: drop-shadow(2px 2px 8px #f7dab6) drop-shadow(2px -2px 8px #fbeede);
    }
  }

  .react-flow__node-turbo {
    --node-border-radius: 10px;
    border-radius: var(--node-border-radius);
    display: flex;
    height: 100px;
    min-width: 100px;
    font-weight: 500;
    letter-spacing: -0.2px;
    box-shadow: var(--node-box-shadow);
  }

  .react-flow__node-turbo .wrapper {
    overflow: hidden;
    display: flex;
    padding: 2px;
    position: relative;
    border-radius: var(--node-border-radius);
    flex-grow: 1;
  }

  .gradient:before {
    content: '';
    position: absolute;
    padding-bottom: calc(100% * 1.41421356237);
    width: calc(100% * 1.41421356237);
    background: conic-gradient(from -160deg at 50% 50%, #f2c288 0deg, #f7dab6 120deg, #fbeede 240deg, #f2c288 360deg);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
  }

  .react-flow__node-turbo.selected .wrapper.gradient:before {
    content: '';
    background: conic-gradient(
      from -160deg at 50% 50%,
      #f2c288 0deg,
      #f7dab6 120deg,
      #fbeede 240deg,
      rgba(42, 138, 246, 0) 360deg
    );
    animation: spinner 4s linear infinite;
    transform: translate(-50%, -50%) rotate(0deg);
    z-index: -1;
  }

  @keyframes spinner {
    100% {
      transform: translate(-50%, -50%) rotate(-360deg);
    }
  }

  .react-flow__node-turbo .inner {
    background: var(--bg-color);
    border-radius: var(--node-border-radius);
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    position: relative;
  }

  .react-flow__node-turbo .icon {
    margin-right: 8px;
  }

  .react-flow__node-turbo .imagewrapper {
    border-radius: var(--node-border-radius);
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: relative;
  }

  .react-flow__node-turbo .imagewrapper img {
    width: 100%;
    height: 100%;
  }

  .react-flow__node-turbo .title {
    font-size: 16px;
    margin-bottom: 2px;
    line-height: 1;
  }

  .react-flow__node-turbo .subline {
    font-size: 12px;
    color: #777;
  }

  .react-flow__node-turbo .cloud {
    border-radius: 100%;
    width: 300px;
    height: 300px;
    right: 0;
    position: absolute;
    top: 0;
    transform: translate(50%, -50%);
    display: flex;
    transform-origin: center center;
    padding: 2px;
    overflow: hidden;
    // box-shadow: var(--node-box-shadow);
    z-index: 1;
  }

  .react-flow__node-turbo .pyramid div {
    box-shadow: none;
    background-color: 'red';
    padding: 2px;
  }

  .react-flow__node-turbo .cloud div {
    background-color: var(--bg-color);
    flex-grow: 1;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .react-flow__handle {
    opacity: 0;
  }

  .react-flow__handle.source {
    right: -10px;
  }

  .react-flow__handle.target {
    left: -10px;
  }

  .react-flow__node:focus {
    outline: none;
  }

  .react-flow__edge .react-flow__edge-path {
    stroke: url(#edge-gradient);
    stroke-width: 2;
    stroke-opacity: 0.75;
  }

  .react-flow__controls button path {
    fill: var(--text-color);
  }

  .react-flow__attribution {
    background: rgba(200, 200, 200, 0.2);
  }
`
